import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import styled from "@emotion/styled";
import { rhythm } from "utils/typography";
import { mq } from "utils/helper";
import { ThemeContext } from "context/theme-context";
import { LocaleContext } from "context/locale-context";
import useTranslations from 'hooks/useTranslations';

import Layout from "components/layout";
import Seo from "components/seo";
import PreviewCompatibleImage from "components/PreviewCompatibleImage.js";

const P = styled.p({
  letterSpacing: "0.47px",
  lineHeight: "27px"
});

function DigitalMemory({ data }) {
  const siteTitle = data.site.siteMetadata.title;
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  const image = data.image;
  const text = data.intro;
  const metadata = {title: "memory"}
  return (
    <Layout title={siteTitle}>
      <Seo metadata={metadata} />
      <div>
        <div
          css={{
            paddingTop: rhythm(4),
            paddingBottom: rhythm(1),
            backgroundColor: colors.dark,
            backgroundImage:
              `linear-gradient(180deg, rgba(0,0,0,0.63) 0%, ${colors.primary_transparent} 100%)`,
            color: colors.light,
            direction: isRTL ? "rtl" : "ltr",
            [mq[1]]: {
              paddingTop: rhythm(1)
            }
          }}
        >
          <div
            css={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: rhythm(50),
              margin: "auto",
              [mq[3]]: {
                margin: `0 ${rhythm(1)} 0`
              },
              [mq[1]]: {
                display: "block",
              }
            }}
          >
            <h1
              css={{
                margin: 0,
                borderBottom: `6px solid ${colors.primary}`,
                fontSize: isRTL ? "2.2rem" : "3rem",
                [mq[1]]: {
                  margin: `${rhythm(2)} 0`
                }
              }}
            >
              {tr("Syrian digital memory")}
            </h1>
            <p
              css={{
                maxWidth: "560px",
                display: "block",
                opacity: 0.75,
                fontSize: "1.3rem",
                margin: 0
              }}
            >
              {tr("memory intro")}
            </p>
          </div>
          <PreviewCompatibleImage
            imageInfo={{
              image: image,
              alt: "an image"
            }}
            imageStyle={{
              maxWidth: "960px",
              margin: `${rhythm(3)} auto`,
              [mq[3]]: {
                margin: `${rhythm(3)} ${rhythm(1)}`
              },
            }}
          />
        </div>
        <div
          css={{
            maxWidth: rhythm(50),
            margin: "6rem auto",
            direction: isRTL ? "rtl" : "ltr",
            [mq[1]]: {
              margin: rhythm(1)
            }
          }}
        >
          <MDXProvider
            components={{
              p: P
            }}
          >
            <MDXRenderer>{text.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </Layout>
  );
}

DigitalMemory.propTypes = {
  data: PropTypes.object
};

export default DigitalMemory;

export const pageQuery = graphql`
  query Memory($locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    intro: mdx(
      frontmatter: { title: { eq: "index" } }
      fileAbsolutePath: { regex: "/memory/" }
      fields: { locale: { eq: $locale } }
    ) {
      body
    }
    image: file(name: { eq: "memory" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
